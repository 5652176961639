// Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FullStory } from '@fullstory/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// Components
import UserScriptList from '../../components/UserScriptList.js';
import PaymentDue from '../../components/Modals/paymentDue.js';
import FreeTrial from '../../components/Modals/freeTrial.js';
import UserCreditBar from './userCreditBar.js';
import Icon from '../../components/Elements/Icon.js';

// Services
import scriptService from '../../services/scriptService.js';
import UserService from '../../services/userService.js';
import subscriptionService from '../../services/subscriptionService.js';

// Set User Context
import { UserContext } from '../../App.js';
import SessionContext from '../../context/Session';

// Construct Component
const WelcomePage = () => {
  const { user } = useContext(UserContext);
  const { isMobile, visitedWelcome, setVisitedWelcome } = useContext(SessionContext);
  const [scripts, setScripts] = useState([]);
  const [paymentDueIsOpen, setPaymentDueIsOpen] = useState(false);
  //const [isFreeTrialOpen, setIsFreeTrialOpen] = useState(false);
  const [modalCalled, setModalCalled] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDeleteScript = (scriptId) => {
    UserService.addActionLog('Delete Script', scriptId, 'script');
    FullStory('trackEvent', {
      name: 'DeleteScriptMyScripts',
    });

    setScripts((scripts) => scripts.filter((s) => s.id !== scriptId));
  };

  const checkNewUser = () => {
    if (!visitedWelcome && user && (!user.first_name || !user.last_name)) {
      window.location.href = `/app/newUserWelcome`;
    }
    return;
  };

  useEffect(() => {
    async function setup() {
      try {
        if (!user || user.id === undefined) return;

        checkNewUser();

        UserService.addActionLog('View Script List');
        const userScripts = await scriptService.getScripts();
        setScripts(userScripts);

        setLoading(false);

        if (user && user.invoiceDue && user.invoiceDue.length > 0) {
          setPaymentDueIsOpen(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setup();
  }, [user]);

  const handleScriptUpload = () => {
    UserService.addActionLog('New Project ', null, null, 'From View Scripts');
    FullStory('trackEvent', {
      name: 'InitiateNewScriptMyScripts',
    });

    window.location.replace(`${window.location.origin}/app/newProject/1`);
  };

  return (
    <>
      {!loading ? (
        <div className="Page lightGrayBackground">
          <div>
            <div className="fullWidthContainer">
              <div className="scriptListContainer">
                <div className="fullWidthContainer Padded">
                  <span className="ThreePortionFlex large bold" style={{ marginTop: '-20px' }}>
                    My Scripts
                  </span>
                </div>

                <div className="fullWidthContainer Padded sizeUp">
                  Never miss a shot. Always make your day.
                </div>

                <UserCreditBar
                  level={user?.level ?? 'basic'}
                  availableCredits={user?.availableCredits ?? null}
                />

                <div style={{ display: 'flex', width: '100%' }}>
                  {scripts && scripts.length > 0 ? (
                    <div className="FullWidthFlex" style={{ textAlign: '-webkit-center' }}>
                      {scripts.map((script) => (
                        <UserScriptList
                          script={script}
                          onDeleteScript={handleDeleteScript}
                          key={script.id}
                          creditsAvailable={user.credits}
                        />
                      ))}

                      <div className={isMobile ? 'fullWidthContainer' : 'OnePortionFlex'}>
                        <div
                          className="projectCard"
                          onClick={handleScriptUpload}
                          style={{
                            ...(isMobile && { width: '100%' }),
                          }}
                        >
                          <div className="FullWidthFlex projectCardHeader">
                            <span className="ThreePortionFlex projectCardHeaderText">
                              Your Next Project
                            </span>
                          </div>

                          <div className="projectCardBody">
                            <a>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ padding: '9px', color: '#ccc', fontSize: '72' }}
                              />
                            </a>
                          </div>

                          <div className="FullWidthFlex projectCardFooter">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="fullWidthContainer">
                      <div className="OnePortionFlex"></div>
                      <div className="TenPortionFlex centered" style={{ maxWidth: '400px' }}>
                        <div className="Padded large bold">
                          <Icon
                            icon="FilePointer"
                            color="Gray"
                            customClass="circleIcon"
                            customStyle={{
                              width: '65px',
                              marginBottom: '-10px',
                              padding: '20px',
                              paddingLeft: '25px',
                              margin: '10px',
                              backgroundColor: '#ECECEC',
                            }}
                          />
                          <br />
                          You have no scripts yet
                        </div>
                        <div className="Padded subHeader">
                          You can generate a shotlist in a few minutes from a narrative script or
                          from a visual treatment in any language.
                        </div>
                        <div className="Padded">
                          <button
                            onClick={handleScriptUpload}
                            className="buttonClone"
                            style={{ width: '100%' }}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                      <div className="OnePortionFlex"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <PaymentDue modalIsOpen={paymentDueIsOpen} setModalIsOpen={setPaymentDueIsOpen} />
      {/*user && isFreeTrialOpen && (
        <FreeTrial isOpen={isFreeTrialOpen} onRequestClose={finishFreeTrial} userDetails={user} />
      )*/}
    </>
  );
};

export default WelcomePage;
